import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ComplainPage.css'
import Design from '../../Assets/Images/backgroundDesign.svg';
import LeftDesign from '../../Assets/Images/backgroundDesign1.svg'
import completeIcon from '../../Assets/Images/completeIcon.svg'
import {Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import SearchIcon from '@mui/icons-material/Search';


export default function ComplainPage() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const navigate= useNavigate();
  const [commissionerateList, setCommissionerateList] = useState([]);
  const [pacsList, setPacsList] = useState([]);
  const [defectiveItemList, setDefectiveItemList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedcommissionerateName, setSelectedCommissionerateName] =useState( );
  const [selectedPacsName, setSelectedPacsName] =useState( );
  const [selectedDefectiveItemName, setSelectedDefectiveItemName] =useState( );
  const [complaintDescription, setComplaintDescription] =useState( );

  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [ownerName, setOwnerName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [openDialog, setOpenDialog] =useState(false);

  const [complaintNo, setComplaintNo] =useState( );
  const [loading, setLoading] =useState(false);
  const suggestionRef = useRef();

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/commissionrate/fetch`)
      .then((response) => {
        setCommissionerateList(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [BACKEND_URL]);
console.log("test")

  const handleCommissionerateChange = async (e) => {
    const selectedValue = e.target.value;
    setSelectedCommissionerateName(selectedValue);
  
    const districtId = commissionerateList.filter((item) => item.districtName === selectedValue);
    try {
      const response = await axios.get(`${BACKEND_URL}/pacs/fetch?districtId=${districtId[0]?.id}`);
      setPacsList(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  // const handlePacsChange = (e) => {
  //   setSelectedPacsName(e.target.value);
  // }

  const handlePacsChange = async (e) => {
    const selectedValue = e.target.value;
    setSelectedPacsName(selectedValue);
  
    const pacsId = pacsList.filter((item) => item.districtName === selectedValue);
    try {
      const response = await axios.get(`${BACKEND_URL}/customer/fetch?pacsId=${pacsId[0]?.id}`);
      setCustomerList(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

const handleOwnerNameChange = (e) => {
  const value = e.target.value;
  setOwnerName(value);
  if (value === '') {
    setFilteredCustomers([]);
    return;
  }
  const filteredCustomers = customerList.filter((customer) =>
    customer.customerName.toLowerCase().includes(value.toLowerCase())
  );
  setFilteredCustomers(filteredCustomers.map(customer => customer.customerName));
};

const handleOwnerNameSelect = async  (customer) => {
  setOwnerName(customer);

  const selectedOwner = customerList.filter((item)=> item.customerName === customer)
  setFilteredCustomers([]);

  setEmail(selectedOwner[0]?.email);
  setPhoneNo(selectedOwner[0]?.contactNo);
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
      setFilteredCustomers([]);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);
  
  const handleDefectiveItemChange = (e) => {
    setSelectedDefectiveItemName(e.target.value);
  }
  
  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/item/fetch`)
      .then((response) => {
        setDefectiveItemList(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [BACKEND_URL]);

  const handleClearALL = () => {
    setCommissionerateList([]);
    setPacsList([]);
    setCustomerList([]);
    setSelectedCommissionerateName('');
    setComplaintDescription('');
    setDefectiveItemList([]);
    setEmail('');
    setFilteredCustomers([]);
    setOwnerName('');
    setPhoneNo('');
    setSelectedPacsName('');
    setSelectedDefectiveItemName('');
  }

  const saveData = () => {

    if(!selectedcommissionerateName || !selectedPacsName || !ownerName || !email || !selectedDefectiveItemName){
      toast.error("Please fill Mandatory Sections!!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("nhjhygfyukjhb")
      return;
    }

    const requestBody ={
      districtName:selectedcommissionerateName,
      pacsName: selectedPacsName,
      customerName:ownerName,
      mobileNo: phoneNo,
      email: email,
      complaints: selectedDefectiveItemName,
      description:complaintDescription,
    }
    setLoading(true);
    axios
        .post(`${BACKEND_URL}/complaint/save`, requestBody)
        .then((response) => {
          console.log("test",response)
          handleClearALL();
          setOpenDialog(true)
          setComplaintNo(response.data?.complaintNo);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error saving data:', error);          
          setLoading(false);
        });
  }

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  const handlecloseDialog = () => {
    setOpenDialog(false);
    navigate(`/`);
    setComplaintNo('');
  };

  const handleBack = () => {
    handleClearALL();
    navigate(`/`);
  };
  

  return (
    <div>

      <div style={{height:'100vh'}} className='complainPage'>

        <div className='complainDesign' style={{ display: "flex", alignItems: "flex-end" }}>
          <img src={LeftDesign} alt="logo" />
        </div>

        <div className='complainDesign'>
          <img src={Design} alt="logo" />
        </div>

        <div className="complainContainer">

          <div className="complainContainerHeaderSection">
            Register Your Complaint
          </div>

          <div className="complainContainerMiddleSection">

            <div className='middleSectionBox'>

              <div className='middleSectionBoxContainer'>
                <div className='middleSectionBoxContainerTxt'>Commissionerate <span style={{color:'red'}}>*</span></div>
                <div style={{width:"100%"}}>
                  <select className="middleSectionBoxContainerTxtBox1" name='commissionerateName' value={selectedcommissionerateName}
                  onChange={handleCommissionerateChange}>
        

                    <option value="">Select Commissionerate</option>
                    {commissionerateList?.map((item) => (
                    <option value={item.districtName}>
                      {item.districtName}
                    </option>
                  ))}
                  </select>
                </div>

              </div>

              <div className='middleSectionBoxContainer'>
                <div className='middleSectionBoxContainerTxt'>PACS Name <span style={{color:'red'}}>*</span></div>
                  <div style={{width:"100%"}}>
                    <select className="middleSectionBoxContainerTxtBox1" name='pacsName' value={selectedPacsName}
                    onChange={handlePacsChange}>
                      <option value="">Select PACS Name</option>
                      {pacsList?.map((item) => (
                    <option value={item.districtName}>
                      {item.districtName}
                    </option>
                  ))}
                    </select>
                  </div>

              </div>
            </div>

            <div className='veticleLine'> </div>

            <div className='middleSectionBox'>

              <div className='middleSectionBoxContainer'>
                <div className='middleSectionBoxContainerTxt'>Item Owner Name <span style={{color:'red'}}>*</span></div>
                <div style={{width:"100%"}}>
                  <div style={{ position: 'relative',display:"flex",alignItems:"center",gap:"2px" }}>
                    <input className="middleSectionBoxContainerTxtBox"  placeholder='Search Owner Name'  value={ownerName}
                    onChange={handleOwnerNameChange}/>
                    {filteredCustomers.length > 0 && (
                  <div ref={suggestionRef}  className='suggestionContainer'>
                    <ul className="suggestions">
                      {filteredCustomers.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => handleOwnerNameSelect(customer)}
                          className={index === selectedSuggestionIndex ? 'selected' : ''}
                        >
                          {customer}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                    
                  </div>
                </div>

              </div>

              <div className='middleSectionBoxContainer'>
                <div className='middleSectionBoxContainerTxt'>Owner Email <span style={{color:'red'}}>*</span></div>
                  <div style={{width:"100%"}}>
                    <input className="middleSectionBoxContainerTxtBox" placeholder='Email' value={email} name='email'
                    onChange={(e) => setEmail(e.target.value)}/>
                  </div>
              </div>
            </div>

            <div className='middleSectionBox'>

              <div className='middleSectionBoxContainer'>
                <div className='middleSectionBoxContainerTxt'>Owner Phone Number</div>
                <div style={{width:"100%"}}>
                <input className="middleSectionBoxContainerTxtBox" style={{background:"rgb(209 209 209)"}} placeholder='Phone Number' value={phoneNo}  disabled/>
                </div>

              </div>

              <div className='middleSectionBoxContainer'>
                {/* empty */}

              </div>
            </div>

            <div className='veticleLine'> </div>

            <div className='middleSectionBox'>

              <div className='middleSectionBoxContainer'>
                <div className='middleSectionBoxContainerTxt'>Defective Item <span style={{color:'red'}}>*</span></div>
                <div style={{width:"100%"}}>
                  <select className="middleSectionBoxContainerTxtBox1" name='defectiveItemName' value={selectedDefectiveItemName}
                    onChange={handleDefectiveItemChange}>
                    <option value="">Select Defective Item</option>
                    {defectiveItemList?.map((item) => (
                    <option value={item.name}>
                      {item.name}
                    </option>
                  ))}
                  </select>
                </div>

              </div>

              <div className='middleSectionBoxContainer'>
                {/* empty */}

              </div>
            </div>

            <div className='middleSectionBox'>

              <div className='middleSectionBoxContainer' style={{width:"100%"}}>
                <div className='middleSectionBoxContainerTxt'>Complaint Description</div>
                <div style={{width:"100%"}}>
                  <textarea className="middleSectionBoxContainerTxtArea" placeholder='Type here...' 
                  name='complaintDescription' value={complaintDescription} onChange={(e) => setComplaintDescription(e.target.value)}/>
                </div>

              </div>
            </div>
            
          </div>

          <div className="complainContainerEndSection">
            <button className='cancelBtn' onClick={handleBack}>Cancel</button>
            <button className='submitBtn' onClick={saveData}> 
            {loading ? <CircularProgress size={20} /> : 'Submit'}</button>
          </div>

        </div>

      </div>


      <Dialog open={openDialog}>
        <DialogTitle className='dialogTitle'>
          <div><img src={completeIcon} alt="logo" /></div>
          <div className='tiltleHeader'>
            <div className='tiltleHeaderTxt'>Complaint Registered Successfully!</div>
            <div className='tiltleHeaderSubTxt'>Complaint Number: {complaintNo}</div>
          </div>
            
        </DialogTitle>
        <DialogContent style={{ padding: "0px" }}>
            
          <div className='dialogContent'>
            <div className='dialogContentTxt'>Your issue will be resolved within 48 working hours</div>
            <div className='dialogContentSubTxt'>*Our working hours are Monday to Saturday 10 AM to 6 PM. Holidays are not counted.</div>
              
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <button className='submitBtn' onClick={handlecloseDialog}>Close</button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
