import React from 'react'
import './Home.css'
import { useNavigate } from 'react-router-dom';
import Design from '../../Assets/Images/backgroundDesign.svg';
import LeftDesign from '../../Assets/Images/backgroundDesign1.svg'
import BiharLogo from '../../Assets/Images/biharscbLogo.svg'
import SoulLogo from '../../Assets/Images/soulLogo.svg';

export default function Home() {
    const navigate= useNavigate();

    const handleGoToComplainPage = () => {
        navigate(`/ComplainPage`);
      };

  return (
    <div>

        <div style={{height:'100vh'}} className='homepage'>
            <div className='homeDesign' style={{ display: "flex", alignItems: "flex-end" }}>
              <img src={LeftDesign} alt="logo" />
            </div>

            <div className='homeContainer'>
              <div className='homeContainerSection'>

                <div className='homeContainerTopSection'>
                  <div ><img src={BiharLogo} alt="logo" /></div>
                  <div className='homeContainerTopSectionTxt'>The Bihar State Cooperative Bank Ltd </div>
                </div>

                <div className='homeContainerBottompSection'>
                  <div className='bottomSectionBox'>
                    <div className='bottomSectionBoxContainer'>
                      <div className='bottomSectionBoxTxt1'>Welcome to Complaint Desk!</div>
                      <div className='bottomSectionBoxTxt2'>PACS Computerization Project </div>

                    </div>
                    <div className='bottomSectionBoxContainer'>
                      <div className='bottomSectionBoxTxt3'>CLUSTER-4</div>
                      <div className='bottomSectionBoxTxt3'> Mungar - Saran - Bhagalpur</div>

                    </div>

                  </div>

                  <div >
                    <button className='buttonSection' onClick={handleGoToComplainPage}>Register Your Complaint</button>

                  </div>

                </div>

              </div>

              <div className='homeContainerSecondSection' >
                <div className='secondSectionTxt1'>Operated By</div>
                <div className='secondSectionTxt2'>Sustainable Outreach Universal Leadership Limited</div>
                <div><img src={SoulLogo} alt="logo" /></div>
                
              </div>
            </div>

            <div className='homeDesign'>
              <img src={Design} alt="logo" />
            </div>
           
        </div>

    </div>
  )
}
