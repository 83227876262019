import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/LandingPage/Home';
import ComplainPage from './Components/ComplainPage/ComplainPage';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ToastContainer/>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="ComplainPage" element={<ComplainPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
